import * as Sentry from '@sentry/node'
import { observer } from 'mobx-react'
import Router, { useRouter } from 'next/router'
import { useState, useEffect, useCallback, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { api } from '@teamflow/client-api'
import {
    Box,
    Text,
    Link,
    Input,
    Button,
    ItemWithMessage,
    Stack,
} from '@teamflow/design'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import { LoginRequestBody, Product, Role } from '@teamflow/types'
import { CenterContent } from '@teamflow/web/src/components/common'
import GoogleButton from '@teamflow/web/src/components/common/GoogleButton'
import HuddleDivider from '@teamflow/web/src/components/common/HuddleDivider'
import HuddleHeader from '@teamflow/web/src/components/common/HuddleHeader'
import HuddleHeading from '@teamflow/web/src/components/common/HuddleHeading'
import HuddleLoader from '@teamflow/web/src/components/common/HuddleLoader'
import PasswordButton from '@teamflow/web/src/components/common/PasswordButton'
import { MIN_PASS_LEN } from '@teamflow/web/src/constants'
import { isValidEmail } from '@teamflow/web/src/helpers/email'
import { getRouteIfRequireCreditCard } from '@teamflow/web/src/helpers/getRouteIfRequireCC'
import { Flow, startFlow } from '@teamflow/web/src/helpers/userFlow'
import useEnableScroll from '@teamflow/web/src/hooks/useEnableScroll'
import useQueryParams from '@teamflow/web/src/hooks/useQueryParams'
import { sharedInstance as electron } from '@teamflow/web/src/verse/services/ElectronService'

import { configureTranslations } from '../src/util'

import type { ChangeEvent, MouseEvent } from 'react'

configureTranslations(['common', 'login'])

const translationPage = 'login'
const tKeys = allTKeys.login

const zIndex1 = { zIndex: 1 }
const zIndex0 = { zIndex: 0 }

export default observer(function LoginPage() {
    const user = rootStore.users.localUser
    const userLoading = rootStore.users.localUserLoading

    const { t } = useTranslation(translationPage)
    const router = useRouter()
    const hideSignUp = router.query.hideSignUp === 'true'
    const fillInEmail =
        typeof router.query.email === 'string' ? router.query.email : ''
    const [form, setForm] = useState({ email: fillInEmail, password: '' })
    const [loading, setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loggingIn, setLoggingIn] = useState(false)
    const [ssoDomains, setSsoDomains] = useState<string[] | null>(null)
    const [sso, setSso] = useState(false)

    const emailInvalid = !form.email || !isValidEmail(form.email)
    const passwordInvalid =
        !form.password || form.password.length < MIN_PASS_LEN
    const formInvalid = emailInvalid || passwordInvalid

    useEnableScroll()

    const { op, invitation } = useQueryParams()
    const fetchSsoDomains = useCallback(async () => {
        const { data: domains } = await api.sso.listDomains()
        setSsoDomains(domains ?? [])
    }, [])

    useEffect(() => {
        if (!electron.available) {
            api.sso.listUrls().then(async (response) => {
                const urls = response.data
                if (urls && urls.includes(window.location.origin)) {
                    const { data } = await api.sso.execute({
                        url: window.location.origin,
                        origin: 'desktop',
                    })

                    if (data) {
                        const { redirect } = data
                        window.location.replace(redirect)
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        setForm((form) => {
            if (form.email === '') return { ...form, email: fillInEmail }
            else return form
        })
    }, [fillInEmail])

    useEffect(() => {
        if (ssoDomains === null) {
            fetchSsoDomains().catch((error) => {
                Sentry.captureException(error, {
                    tags: {
                        is_sso_domains_error: true,
                    },
                })
            })
        }
    }, [fetchSsoDomains, ssoDomains])

    useEffect(() => {
        if (ssoDomains) {
            const domain = form.email.substring(form.email.lastIndexOf('@') + 1)
            setSso(ssoDomains.includes(domain))
        } else {
            setSso(false)
        }
    }, [form.email, ssoDomains])

    /**
     * On first load check for the trial extend
     * parameters, store in localStorage
     */
    useEffect(() => {
        if (op === 'extend') {
            rootStore.marketing.setExtendTrial(true)
        }
    }, [op])

    useEffect(() => {
        if (user && !Router.asPath.includes('as=guest')) {
            if (user.role === Role.GUEST) {
                setLoading(false)
                return
            }

            if (typeof Router.query.redirect === 'string') {
                void Router.push(Router.query.redirect)
            } else if (user.currentOrgSlug) {
                // Redirect to organization
                void getRouteIfRequireCreditCard(user).then(async (route) => {
                    await Router.push(route)
                })
            } else {
                // If the user doesn't have a currentOrgSlug, use redirect to
                // switch organizations cleanly
                void Router.push('/redirect')
            }
        } else if (!userLoading) {
            setLoading(false)
        }
    }, [user, userLoading])

    const loginWithSso = useCallback(async () => {
        const { data } = await api.sso.execute({
            email: form.email,
            origin: electron.available ? 'desktop' : 'web',
        })

        if (data) {
            const { redirect } = data
            window.location.replace(redirect)
        } else {
            setErrorMsg('Unable to get sso configuration')
        }
    }, [form.email])

    const loginWithEmailAndPassword = useCallback(async () => {
        const body: LoginRequestBody = {
            email: form.email,
            password: form.password,
            invitation,
        }

        if (router.query.as) {
            body.inviteCode = router.query.inviteCode as string
            body.asGuest = true
        }

        const { data: result } = await api.login(body)
        if (result) {
            const { userOrg, idToken, user } = result
            if (userOrg) {
                rootStore.users.updateLocalUser(userOrg)
                // kick users to eventsdot if they log in to an event org in appdot
                if (
                    idToken &&
                    userOrg.product === Product.Event &&
                    process.env.DEPLOY_ENV === 'production'
                ) {
                    window.location.replace(
                        `https://events.teamflowhq.com/redirect?idToken=${idToken}`
                    )
                }
            }
            if (user) {
                rootStore.users.updateGlobalUser(user)
            }

            startFlow(Flow.Login, { timeout: 15000 })

            if (typeof router.query.redirect === 'string') {
                await router.push(router.query.redirect)
            } else {
                await router.push(`/redirect`)
            }
        } else {
            setErrorMsg(t(tKeys.incorrectEmailOrPassword))
        }
    }, [form.email, form.password, invitation, router, t])

    const loginWithGoogle = useCallback(
        async (e: MouseEvent) => {
            e.preventDefault()
            if (
                electron.available &&
                (await electron.atLeastVersion('10.0.0'))
            ) {
                // updated flow that opens native browser to auth
                // https://developers.googleblog.com/2020/08/guidance-for-our-effort-to-block-less-secure-browser-and-apps.html
                const { idToken, accessToken, domain } =
                    await electron.waitForGoogleSSO()

                setLoggingIn(true)

                const data = {
                    // this is the form the api needs
                    id_token: idToken,
                    accessToken,
                    domain,
                } as any

                const query = Object.keys(data)
                    .filter((key) => !!data[key])
                    .map((key) => `${key}=${data[key]}`)
                    .join('&')

                // gets an idToken, acessToken, & domain after sso that is
                // passed to the app from the api via a local server
                // @see main.ts
                // This endpoint is proxied by the web server so the desktop app opens it internally. The
                // API link is considered "external" and opened in a browser.
                window.location.replace(`/api/auth/google/token?${query}`)
            } else {
                let url = '/api/auth/google'
                let query: string | null = null
                if (router.query.as) {
                    query = `?asGuest=true&inviteCode=${router.query.inviteCode}`
                    url += query
                }
                if (typeof router.query.redirect === 'string') {
                    url += url.includes('?') ? '&' : '?'
                    url += `redirect=${encodeURIComponent(
                        router.query.redirect
                    )}`
                }
                if (typeof router.query.invitation === 'string') {
                    url += url.includes('?') ? '&' : '?'
                    url += `invitation=${router.query.invitation}`
                }

                window.location.replace(url)
            }
        },
        [
            router.query.as,
            router.query.invitation,
            router.query.inviteCode,
            router.query.redirect,
        ]
    )

    const onInputChange =
        (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
            setErrorMsg('')
            setForm({ ...form, [name]: e.currentTarget.value })
        }

    const onClickLogin = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            if (sso) void loginWithSso()
            else void loginWithEmailAndPassword()
        },
        [loginWithEmailAndPassword, loginWithSso, sso]
    )

    const onFormSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            if (sso) void loginWithSso()
            else void loginWithEmailAndPassword()
        },
        [loginWithEmailAndPassword, loginWithSso, sso]
    )

    if (loading || userLoading) {
        return (
            <>
                <HuddleHeader />
                <HuddleLoader>{t(tKeys.loading)}</HuddleLoader>
            </>
        )
    }

    return (
        <>
            <HuddleHeader showSignUp={!hideSignUp} />
            <CenterContent>
                <HuddleHeading>{t(tKeys.welcomeBack)}</HuddleHeading>
                <GoogleButton onClick={loginWithGoogle} isLoading={loggingIn}>
                    {t(tKeys.logInWithGoogle)}
                </GoogleButton>
                <HuddleDivider />
                <form onSubmit={onFormSubmit}>
                    <Stack space="space16">
                        <ItemWithMessage
                            level="danger"
                            message={t(tKeys.pleaseEnterAValidEmail)}
                            show={!!errorMsg && emailInvalid}
                            data-testid="login-email-error"
                        >
                            <Input
                                label={t(tKeys.workEmail)}
                                tabIndex={1}
                                onChange={onInputChange('email')}
                                type="email"
                                id="email"
                                isInvalid={!!errorMsg && emailInvalid}
                                data-testid="login-email"
                                defaultValue={fillInEmail}
                                aria-describedby="email-helper-text"
                                placeholder="name@workemail.com"
                            />
                        </ItemWithMessage>

                        {!sso && (
                            <Box position="relative">
                                <Box
                                    position="absolute"
                                    right="none"
                                    top="none"
                                    __cssOverrides={zIndex1}
                                >
                                    <Text size="textSize14" weight="600">
                                        <Link
                                            tabIndex={4}
                                            href="/reset-password"
                                        >
                                            {t(tKeys.forgotPassword)}
                                        </Link>
                                    </Text>
                                </Box>
                                <Box
                                    position="relative"
                                    __cssOverrides={zIndex0}
                                >
                                    <ItemWithMessage
                                        level="danger"
                                        message={t(
                                            tKeys.passwordsMustBeAtLeastAmountCharacters,
                                            { amount: MIN_PASS_LEN }
                                        )}
                                        show={!!errorMsg && passwordInvalid}
                                        data-testid="login-password-error"
                                    >
                                        <Input
                                            label={t(tKeys.password)}
                                            tabIndex={2}
                                            min={MIN_PASS_LEN}
                                            onChange={onInputChange('password')}
                                            onSubmit={
                                                sso
                                                    ? loginWithSso
                                                    : loginWithEmailAndPassword
                                            }
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            id="password"
                                            isInvalid={
                                                !!errorMsg && passwordInvalid
                                            }
                                            data-testid="login-password"
                                            aria-describedby="password-helper-text"
                                            placeholder={t(
                                                tKeys.minAmountCharacters,
                                                { amount: MIN_PASS_LEN }
                                            )}
                                            autoCapitalize="off"
                                            autoCorrect="off"
                                            after={
                                                <PasswordButton
                                                    showPassword={showPassword}
                                                    setShowPassword={
                                                        setShowPassword
                                                    }
                                                />
                                            }
                                        />
                                    </ItemWithMessage>
                                </Box>
                            </Box>
                        )}
                    </Stack>

                    <ItemWithMessage
                        level="danger"
                        light
                        message={errorMsg}
                        show={!!errorMsg && !formInvalid}
                        data-testid="login-submit-error"
                    >
                        <Button
                            data-testid="login-submit-credentials"
                            size="lg"
                            isFullWidth
                            tabIndex={3}
                            disabled={!form.email || (!form.password && !sso)}
                            marginTop="space24"
                            onClick={onClickLogin}
                        >
                            {sso
                                ? t(tKeys.logInWithSso)
                                : t(tKeys.logInWithEmailAndPassword)}
                        </Button>
                    </ItemWithMessage>
                </form>
            </CenterContent>
        </>
    )
})
