import { api } from '@teamflow/client-api'

export const isValidEmail = (email: string): boolean => {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
}

/**
 * Check if an email domain is blocked. Work emails are not blocked.
 * If the email is invalid (i.e. does not have a domain), return false
 * @param email
 */
export const isGenericEmail = async (email: string): Promise<boolean> => {
    const domain = email.split('@')[1]

    if (domain) {
        const domainBlocked = await api.util.blocked({ domain })
        return !!domainBlocked?.data?.blocked
    }

    return false
}
