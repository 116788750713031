import { styled } from '@stitches/react'

import { colorPalette, Icon } from '@teamflow/design'

type Props = {
    hasDarkBackground?: boolean
    showPassword: boolean
    setShowPassword: (value: boolean) => void
}

const Button = styled('button', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    variants: {
        hasDarkBackground: {
            true: {
                '&:active svg': {
                    color: colorPalette.neutral400,
                },
            },
            false: {
                '&:active svg': {
                    color: colorPalette.neutral600,
                },
            },
        },
    },
})

export default function PasswordButton({
    hasDarkBackground = false,
    showPassword,
    setShowPassword,
}: Props) {
    return (
        <Button
            hasDarkBackground={hasDarkBackground}
            onClick={(event) => {
                event.preventDefault()
                setShowPassword(!showPassword)
            }}
        >
            {showPassword ? (
                <Icon
                    name="hidePassword"
                    color="neutral50"
                    style={{
                        marginTop: '1px',
                    }}
                />
            ) : (
                <Icon name="showPassword" color="neutral50" />
            )}
        </Button>
    )
}
