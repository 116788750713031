import { useTranslation } from 'react-i18next'

import { Box, Divider, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

const translationPage = 'common'
const tKeys = allTKeys.common.huddleDivider

export default function HuddleDivider() {
    const { t } = useTranslation(translationPage)

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="space6"
            width="fill"
            __cssOverrides={{ height: 76 }}
        >
            <Box flex={1}>
                <Divider color="neutral30" />
            </Box>
            <Text size="textSize12" weight="600" color="neutral60">
                {t(tKeys.or)}
            </Text>
            <Box flex={1}>
                <Divider color="neutral30" />
            </Box>
        </Box>
    )
}
