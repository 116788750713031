import { isString } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

import { ID_REFRESH_TOKEN_NAME, ID_TOKEN_NAME } from '../constants'

export default function useQueryParams() {
    const router = useRouter()

    const props = useMemo(() => {
        const newProps: Record<string, string> = {}

        for (const key in router.query) {
            if (!(key in router.query)) continue

            const value = router.query[key]
            newProps[key] = isString(value) ? value : ''
        }

        return newProps
    }, [router.query])

    useEffect(() => {
        if (props.idToken) {
            window.localStorage.setItem(ID_TOKEN_NAME, props.idToken)
        }
    }, [props.idToken])

    useEffect(() => {
        if (props.refreshToken) {
            window.localStorage.setItem(
                ID_REFRESH_TOKEN_NAME,
                props.refreshToken
            )
        }
    }, [props.refreshToken])

    return props
}
