import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, colorPalette, Text, Box } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { GoogleIcon } from '@teamflow/ui-icons'

import { isInFrame } from '../../helpers'

const translationPage = 'login'
const tKeys = allTKeys.login

interface Props {
    isLoading?: boolean
    onClick?: React.MouseEventHandler<HTMLElement>
}

const googleIconStyle = {
    marginRight: '16px',
}

export default function GoogleButton({
    isLoading,
    onClick,
    children,
}: React.PropsWithChildren<Props>): JSX.Element {
    const { t } = useTranslation(translationPage)

    const inIFrame = isInFrame()
    const buttonStyles = useMemo(() => {
        return {
            border: `2px solid ${colorPalette.neutral700}`,
            color: colorPalette.neutral800,
            opacity: inIFrame ? 0.4 : 1,
        }
    }, [inIFrame])

    return (
        <Box>
            <Button
                size="md"
                kind="text"
                level="neutral"
                isFullWidth
                disabled={inIFrame}
                leadingIcon={<GoogleIcon style={googleIconStyle} />}
                isLoading={isLoading}
                loadingText={children}
                onClick={onClick}
                style={buttonStyles}
            >
                {children}
            </Button>
            {inIFrame && (
                <Box marginY="space8">
                    <Text
                        align="center"
                        size="textSize14"
                        width="fill"
                        color="neutral80"
                    >
                        {t(tKeys.googleSsoUnavailableWhileEmbeddedInAnotherApp)}
                    </Text>
                </Box>
            )}
        </Box>
    )
}
