import { useEffect } from 'react'

export default function useEnableScroll() {
    // for pages that need overflow
    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])

    return () => {
        // default verse uses hidden
        document.body.style.overflow = 'hidden'
    }
}
