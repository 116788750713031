import { api } from '@teamflow/client-api'
import { ApiErrorCode, UserFrontendModel } from '@teamflow/types'
import { UserTags } from '@teamflow/types/src/tags'

export const getRouteIfRequireCreditCard = async (user: UserFrontendModel) => {
    const id = `${user.accountId}_${user.currentOrgId}`
    const all = await Promise.all([
        api.userOrg.tags.get({
            userOrgId: id,
            tagName: UserTags.SignupRequirePayment,
        }),
        api.userOrg.tags.get({
            userOrgId: id,
            tagName: UserTags.SignupRequireCreditCard,
        }),
    ])

    const route = `/${user.currentOrgSlug}`
    const signupRoute = !all[0].error ? '/neworg' : '/newteam'

    const res = all.find((r) => r.error?.code !== ApiErrorCode.UnknownObject)
    if (!res) {
        return route
    }

    // we have a require payment or CC tag so...
    // check for billing
    const { data, error } = await api.billing.subscriptions.get({
        orgId: user.currentOrgId,
    })

    if (error) {
        // did not find a billing object so need to complete
        return signupRoute
    }

    if (!data?.activeSubscription) {
        // does not have an active subscription so need to complete
        return signupRoute
    }

    // everything is good so continue
    return route
}
