import * as Sentry from '@sentry/react'

import { LogManager } from '@teamflow/lib'
import { track } from '@teamflow/web/src/helpers/analytics'

interface Event {
    name: string
    ts: Date
    properties?: { [key: string]: unknown }
}

interface FlowOptions {
    timeout: number
}

export enum Flow {
    Login = 'login',
}

export enum Events {
    Start = 'start',
    OrgPage = '...org',
    Redirect = 'redirect',
    VerseWrapper = 'verse-wrapper',
    Verse = 'verse',
    VerseInitialized = 'verse-initialized',
    DownloadScreen = 'download-screen',
    Timedout = 'timedout',
    SignUp = 'sign-up',
    Denied = 'denied',
    GuestLogin = 'guest-login',
}

export enum Status {
    Success,
    Duplicate,
    Missing,
}

const eventsByFlow = new Map<Flow, Event[]>()
const timeoutsByFlow = new Map<Flow, number>()

function addTimeout(flow: Flow, options?: FlowOptions) {
    if (timeoutsByFlow.has(flow)) {
        removeTimeout(flow)
    }

    const id = window.setTimeout(() => {
        rejectFlow(flow, Events.Timedout, { options })
    }, options?.timeout)
    timeoutsByFlow.set(flow, id)

    return id
}

function removeTimeout(flow: Flow) {
    if (!timeoutsByFlow.has(flow)) {
        return
    }

    const id = timeoutsByFlow.get(flow)

    if (id === undefined) {
        return
    }

    window.clearTimeout(id)
    timeoutsByFlow.delete(flow)
}

function createEvent(name: Events, properties?: { [key: string]: unknown }) {
    return {
        name,
        ts: new Date(),
        properties,
    }
}

export function startFlow(
    flow: Flow,
    options: FlowOptions = { timeout: 30000 }
) {
    let status = Status.Success
    if (eventsByFlow.has(flow)) {
        status = Status.Duplicate

        // flows should be ended so if this happens, treat it as an error
        Sentry.captureMessage(
            `new ${flow} flow started when one already running`,
            {
                level: 'error',
                extra: {
                    events: eventsByFlow.get(flow),
                },
            }
        )

        eventsByFlow.delete(flow)
        removeTimeout(flow)
    }

    eventsByFlow.set(flow, [createEvent(Events.Start)])
    addTimeout(flow, options)

    track(`${flow}:start`)

    LogManager.global.debug(`${flow} flow started`)

    return status
}

export function trackFlow(
    flow: Flow,
    event: Events,
    properties?: { [key: string]: unknown }
) {
    const events = eventsByFlow.get(flow)
    if (!events) {
        return Status.Success
    }

    events.push(createEvent(event, properties))

    track(`${flow}:${event}`)
    LogManager.global.debug(`${flow} flow event: ${event}`)

    return Status.Success
}

export function rejectFlow(
    flow: Flow,
    event: Events,
    properties?: { [key: string]: unknown }
) {
    const events = eventsByFlow.get(flow)
    if (!events) {
        return Status.Success
    }

    events.push(createEvent(event, properties))

    Sentry.captureMessage(`${flow} rejected`, {
        level: 'error',
        extra: {
            events: events,
        },
    })

    eventsByFlow.delete(flow)
    removeTimeout(flow)
    LogManager.global.error(`${flow} flow rejected: ${event}`)

    return Status.Success
}

export function endFlow(flow: Flow) {
    if (!eventsByFlow.has(flow)) {
        // could be the case if not logging in and just coming
        // back to the org while already logged in
        return Status.Missing
    }

    // this is considered a success so we can just delete the flow
    eventsByFlow.delete(flow)
    removeTimeout(flow)

    track(`${flow}:end`)

    LogManager.global.debug(`${flow} flow ended`)

    return Status.Success
}

export function getFlow(flow: Flow) {
    return eventsByFlow.get(flow)
}
